import React, {useEffect, useRef, useState, useCallback} from "react";
import {Link, useNavigate} from "react-router-dom"
import "./../../assets/css/static/css/dark/style-dark.css";
import TypeIt from "typeit-react";
import axios from 'axios';
import BotheBotWhite from './../../assets/images/whitelogobothebot.svg';
import HumburgerIcon from './../../assets/images/humburgericons.svg';
import { API_URL, API_KEY, MAP_KEY } from './../../config/constant.js';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
export default function Main(){
	const homeRef = useRef()
	const serviceRef = useRef()
	const contactRef = useRef()
	const navigate = useNavigate()
	useEffect(() => {
	   	if(localStorage.getItem("boAuth") === "false"){
			navigate('/feed')
			//window.location.href = "/feed"
		}else{
			navigate('/password')
		}
		const script = document.getElementById('recaptcha-script');
    if (script) {
      script.remove();
    }

    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElems.length) {
      recaptchaElems[0].remove();
    }
	 }, []);
	const toggleMenu = () => {
		document.getElementById('navbar-collapse-toggle').classList.add(['menu-open']);
	}
	const closeMenu = () => {
		document.getElementById('navbar-collapse-toggle').classList.remove(['menu-open']);
	}
	const [menu, setMenu] = useState("home")
	const executeScroll = (type) => {
		setMenu(type)
		document.getElementById('navbar-collapse-toggle').classList.remove(['menu-open']);
		if(type === "home"){
			homeRef.current.scrollIntoView() 
		}
		if(type === "service"){
			serviceRef.current.scrollIntoView() 
		}
		if(type === "contact"){
			contactRef.current.scrollIntoView() 
		}
	}
	const [password, setPassword] = useState("")
	const [passError, setPassError] = useState(false)
	const [textError, setTextError] = useState(false)
	const [buttonDisable, setButtonDisable] = useState(false)
	const handlePassword = (e) => {
		setPassError(false)
		setTextError(false)
		setPassword(e.target.value)
	}
	const handleValidation = () => {
		let formIsValid = true;
	    if(!password){
	    	formIsValid = false;
	        setPassError(true)
	    }
	    return formIsValid;
	}
	const handleForm = async () => {
		if(handleValidation()){
			setButtonDisable(true)
			await axios.post(API_URL+"BoPasswordVerification?code="+API_KEY,{password: password}).then((res)=>{
				if(res.data.status === 200){
					localStorage.setItem('boAuth',false)
					navigate("/feed")
					//window.location.href = "/feed"
				}else{
					setPassError(true)
					setTextError(true)
				}
				setButtonDisable(false)
			})
		}
	}
	const handleKeypress = (e) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      handleForm();
    }
  };
	return (
		<>
		
		    <div className="mob-header dark" id="_header">
		        <div className="d-flex align-items-center">
		            <div className="navbar-brand">
		                <div className="about-avatar">
		                	<Link to="/">
		                    <img src="static/img/bothebot-logo.svg" title="" alt="" />
		                  </Link>
		                </div>
		            </div>
		            <button className="toggler-menu" onClick={toggleMenu}>
		                <span></span>
		                <span></span>
		                <span></span>
		            </button>
		        </div>
		    </div>
		    <header className="header-left d-flex flex-column" id="navbar-collapse-toggle">
		        <div className="close-icon" onClick={closeMenu}>
		            <button className="hl_menu_close">
		                <span></span>
		            </button>
		        </div>
		        <ul className="nav">
		            <li>
		                <Link className="nav-link" to="/">
		                    <span>Home</span>
		                </Link>
		            </li>
		            <li>
		                <Link className="nav-link" to="/#service">
		                    <span>You will love it</span>
		                </Link>
		            </li>
		            <li>
		                <Link className="nav-link" to="/#contact">
		                    <span>Become a first user</span>
		                </Link>
		            </li>
		            <li>
		                <Link className="nav-link active" to="/password">
		                    <span>Product preview</span>
		                </Link>
		            </li>
		        </ul>
		        <div className="header-contact">
		            <label>Reach out:</label>
		            <Link to="mailto:info@bothebot.com">info@bothebot.com</Link>
		        </div>
		    </header>
		    <main>
		        <div className="passwordPageCom">
					<div className="loginDivItem">
						<div className="loginInput">
							<input type="password" className={passError?"form-control error":"form-control"} placeholder="Enter password" onChange={handlePassword} onKeyPress={handleKeypress}/>
							{textError?<span className="lgionErrorFields">Incorrect password. Calling 911 (just kidding, try again)</span>:""}
							<button className="letsBoPassword" type="submit" onClick={handleForm} disabled={buttonDisable}><span className="btnNames">LET'S BO!</span><i className="arrowmyin"></i></button>
						</div>
					</div>
				</div>
		    </main>
		</>
	)
}