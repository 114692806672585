import React, {lazy, Suspense} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./assets/css/loader.css";
import PreLoader from './components/loader/preLoader';
import Home from './components/home/main';
import Password from './components/home/password';
const Index = lazy(() => import('./components/home/index'));
/*const Home = lazy(() => import('./components/home/main'));
const Password = lazy(() => import('./components/home/password'));*/
const Address = lazy(() => import('./components/rnd/address'));
function App() {
  return (
    <BrowserRouter>
      <div className="wrapper">
        <div className="content-wrapper">
          <Suspense fallback={<div><PreLoader /></div>}>
            <Routes>
              <Route path="/:processId" element={<Index />} exact={true} />
              <Route path="/" element={<Home />} exact={true} />
              <Route path="/feed" element={<Index />} exact={true} />
              <Route path="/password" element={<Password />} exact={true} />
              <Route path="/address" element={<Address />} exact={true} />
              </Routes>
          </Suspense>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
