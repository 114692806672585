import React, {useEffect, useRef, useState, useCallback} from "react";
import {Link, useLocation} from "react-router-dom"
import "./../../assets/css/static/css/dark/style-dark.css";
import TypeIt from "typeit-react";
import axios from 'axios';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';
export default function Main(){
	const homeRef = useRef()
	const serviceRef = useRef()
	const contactRef = useRef()
	const { hash } = useLocation();
	useEffect(() => {
		if(hash){
			executeScroll(hash.slice(1))
		}
	},[hash])
	useEffect(() => {
	   	window.addEventListener("scroll", () => {
	   		if(document.getElementById('_header')){
	   			if(window.scrollY > 50){
		     		document.getElementById('_header').classList.add(['fixed-header']);
		     	}else{
		     		document.getElementById('_header').classList.remove(['fixed-header']);
		     	}
	   		}
	     	
	   });
	 }, []);
	const toggleMenu = () => {
		document.getElementById('navbar-collapse-toggle').classList.add(['menu-open']);
	}
	const closeMenu = () => {
		document.getElementById('navbar-collapse-toggle').classList.remove(['menu-open']);
	}
	const [menu, setMenu] = useState("home")
	const executeScroll = (type) => {
		setMenu(type)
		document.getElementById('navbar-collapse-toggle').classList.remove(['menu-open']);
		if(type === "home"){
			homeRef.current.scrollIntoView() 
		}
		if(type === "service"){
			serviceRef.current.scrollIntoView() 
		}
		if(type === "contact"){
			contactRef.current.scrollIntoView() 
		}
	}
	useEffect(() => {
	  const loadScriptByURL = (id, url, callback) => {
	    const isScriptExist = document.getElementById(id);
	 
	    if (!isScriptExist) {
	      var script = document.createElement("script");
	      script.type = "text/javascript";
	      script.src = url;
	      script.id = id;
	      script.onload = function () {
	        if (callback) callback();
	      };
	      document.body.appendChild(script);
	    }
	 
	    if (isScriptExist && callback) callback();
	  }
	  loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=6LdX9ismAAAAAFT_4FA-2BjNHmEvsFzi8CG2mcTy`, function () {
	 
	  });
	}, []);

	const [name, setName] = useState("")
	const [nameError, setNameError] = useState(false)
	const [email, setEmail] = useState("")
	const [emailError, setEmailError] = useState(false)
	const [country, setCountry] = useState("")
	const [countryError, setCountryError] = useState(false)
	const [city, setCity] = useState("")
	const [cityError, setCityError] = useState(false)
	const [brand, setBrand] = useState("")
	const [brandError, setBrandError] = useState(false)
	const validateEmail = (email) => {
	    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	    return re.test(String(email).toLowerCase());
	}
	const handleName = (e) => {
		setNameError(false)
		setName(e.target.value)
	}
	const handleEmail = (e) => {
		setEmailError(false)
		setEmail(e.target.value)
	}
	const handleCountry = (e) => {
		setCountryError(false)
		setCountry(e.target.value)
	}
	const handleCity = (e) => {
		setCityError(false)
		setCity(e.target.value)
	}
	const handleBrand = (e) => {
		setBrandError(false)
		setBrand(e.target.value)
	}
	const handleValidation = () => {
		let formIsValid = true;
		if(!name){
			formIsValid = false;
	        setNameError(true)
		}
		if(!email){
			formIsValid = false;
	        setEmailError(true)
		}
		if(!validateEmail(email)){
	        formIsValid = false;
	        setEmailError(true)
	    }
	    if(!country){
			formIsValid = false;
	        setCountryError(true)
		}
		if(!city){
			formIsValid = false;
	        setCityError(true)
		}
		if(!brand){
			formIsValid = false;
	        setBrandError(true)
		}
	    return formIsValid;
	}
	const [success, setSuccess] = useState(false)
	const [error, setError] = useState(false)
	const [btnDisable, setBtnDisable] = useState(false)
	const handleForm = () => {
		setSuccess(false)
		setError(false)
		if(handleValidation()){
			setBtnDisable(true)
			window.grecaptcha.ready(() => {
			    window.grecaptcha.execute("6LdX9ismAAAAAFT_4FA-2BjNHmEvsFzi8CG2mcTy", { action: 'submit' }).then(async token => {
			      let jsonData = {
			    		name: name,
			    		email: email,
			    		country: country,
			    		city: city,
			    		brandsDescription: brand,
			    		"gToken": token
			    	}
			    	await axios.post("https://bothebotwebsite.azurewebsites.net/api/addWebsiteUser?code=S1xnOe0dAqjIQpvA4EM0MB2uzTRSa3t4758_mfFh4GQ0AzFuvmlblA==",jsonData).then((res)=>{
			    		if(res.data.status === 200){
			    			setSuccess(true)
			    			setName("")
							setEmail("")
							setCountry("")
							setCity("")
							setBrand("")
			    		}else{
			    			setError(true)
			    		}
			    		setBtnDisable(false)
			    	});
			    });
			});
		}
	}
	return (
		<>
		
		    <div className="mob-header dark" id="_header">
		        <div className="d-flex align-items-center">
		            <div className="navbar-brand">
		                <div className="about-avatar">
		                    <img src="static/img/bothebot-logo.svg" title="" alt="" />
		                </div>
		            </div>
		            <button className="toggler-menu" onClick={toggleMenu}>
		                <span></span>
		                <span></span>
		                <span></span>
		            </button>
		        </div>
		    </div>
		    <header className="header-left d-flex flex-column" id="navbar-collapse-toggle">
		        <div className="close-icon" onClick={closeMenu}>
		            <button className="hl_menu_close">
		                <span></span>
		            </button>
		        </div>
		        <ul className="nav">
		            <li>
		                <Link className={(menu === "home")?"nav-link active":"nav-link"} to="#" onClick={() => executeScroll("home")}>
		                    <span>Home</span>
		                </Link>
		            </li>
		            <li>
		                <Link className={(menu === "service")?"nav-link active":"nav-link"} to="#" onClick={() => executeScroll("service")}>
		                    <span>You will love it</span>
		                </Link>
		            </li>
		            <li>
		                <Link className={(menu === "contact")?"nav-link active":"nav-link"} to="#" onClick={() => executeScroll("contact")}>
		                    <span>Become a first user</span>
		                </Link>
		            </li>
		            <li>
		                <Link className="nav-link" to="/password">
		                    <span>Product preview</span>
		                </Link>
		            </li>
		        </ul>
		        <div className="header-contact">
		            <label>Reach out:</label>
		            <Link to="mailto:info@bothebot.com">info@bothebot.com</Link>
		        </div>
		    </header>
		    <main>
		        <section id="home" ref={homeRef} className="home-banner-01" style={{"backgroundImage": `url(static/img/home_banner11.jpg)`}}>
		            <div className="container-fluid">
		                <div className="row min-vh-100 align-items-center">
		                    <div className="col-md-9 col-lg-7">
		                        <div className="ht-text">
		                            <h6>It's here!</h6>
		                            <h6> </h6>
		                            <h1>Shopping made <br /><TypeIt
						                options={{
						                	loop:true,
						                    strings: ['Fun','Easy','Fast'],
						                    speed: 200,
						                    breakLines: false
						                }}
						            /></h1>
		                            
		                            <div className="btn-bar go-to">
		                                <Link to="#" className="px-btn white" onClick={() => executeScroll("contact")}>
		                                    <span>Join us</span>
		                                    <i className="arrow"></i>
		                                </Link>

		                            </div>
		                            
		                        </div>
		                    </div>
		                </div>
		            </div>
		            <div className="go-to go-to-next">
		                <Link to="#" onClick={() => executeScroll("service")}>
		                    <span></span>
		                </Link>
		            </div>
		        </section>
		        <section className="section services-section black-bg" id="services" ref={serviceRef}>
		            <div className="container">
		                <div className="row justify-content-center">
		                    <div className="col-lg-12">
		                        <div className="section-title">
		                            <h2>You will love it</h2>
		                        </div>
		                    </div>
		                </div>
		                <div className="row">
		                    
		                    <div className="col-sm-6 col-lg-4 my-3">
		                        <div className="feature-box-1">
		                            <div className="icon d-flex align-items-center justify-content-center">
		                                <img src="static/img/checkout6.svg" title="" alt="" />
		                            </div>
		                            <div className="feature-content">
		                                <h5>Two steps checkout</h5>
		                                <p>You can immediately checkout and pay from an online or offline image.</p>
		                            </div>
		                        </div>
		                    </div>
		                    
		                    
		                    <div className="col-sm-6 col-lg-4 my-3">
		                        <div className="feature-box-1">
		                            <div className="icon d-flex align-items-center justify-content-center">
		                                <img src="static/img/icon-2.svg" title="" alt="" />
		                            </div>
		                            <div className="feature-content">
		                                <h5>No account needed</h5>
		                                <p>Just go shopping. But will you resit to creating one?</p>
		                            </div>
		                        </div>
		                    </div>
		                    
		                    
		                    <div className="col-sm-6 col-lg-4 my-3">
		                        <div className="feature-box-1">
		                            <div className="icon d-flex align-items-center justify-content-center">
		                                <img src="static/img/icon-3.svg" title="" alt="" />
		                            </div>
		                            <div className="feature-content">
		                                <h5>Not an e-commerce</h5>
		                                <p>It's a technology working on any browser.</p>
		                            </div>
		                        </div>
		                    </div>
		                    
		                    
		                    <div className="col-sm-6 col-lg-4 my-3">
		                        <div className="feature-box-1">
		                            <div className="icon d-flex align-items-center justify-content-center">
		                                <img src="static/img/map2.svg" title="" alt="" />
		                            </div>
		                            <div className="feature-content">
		                                <h5>Find it in a store</h5>
		                                <p>Nearby or far away, you'll find a store that has it.</p>
		                            </div>
		                        </div>
		                    </div>
		                    
		                    
		                    <div className="col-sm-6 col-lg-4 my-3">
		                        <div className="feature-box-1">
		                            <div className="icon d-flex align-items-center justify-content-center">
		                                <img src="static/img/icon-5.svg" title="" alt="" />
		                            </div>
		                            <div className="feature-content">
		                                <h5>Final price upfront</h5>
		                                <p>No added costs at the end. What you see is what you pay.</p>
		                            </div>
		                        </div>
		                    </div>
		                    
		                    
		                    <div className="col-sm-6 col-lg-4 my-3">
		                        <div className="feature-box-1">
		                            <div className="icon d-flex align-items-center justify-content-center">
		                                <img src="static/img/delivery2.svg" title="" alt="" />
		                            </div>
		                            <div className="feature-content">
		                                <h5>Delivery as you wish</h5>
		                                <p>Pick it up in store or the store can ship it or courier to you.</p>
		                            </div>
		                        </div>
		                    </div>
		                    
		                    
		                </div>
		            </div>
		            
		        </section>
		        <section className="section dark-bg" id="contactus" ref={contactRef}>
		            <div className="container">
		                <div className="row justify-content-center">
		                    <div className="col-lg-6">
		                        <div className="section-title">
		                            <h2>Become a first user</h2>
		                            <p>BOthebot and BOtag technology is scheduled to launch in August/September 2023. Join the first group to try this outstanding and disruptive way to go shopping.</p>
		                        </div>
		                    </div>
		                </div>
		                <div className="row flex-row-reverse no-gutters contactus-row mx-auto">
		                    <div className="col-md-12">
		                        <div className="contact-form">
		                            <h2>Join now</h2>
		                                <div className="row">
		                                    <div className="col-md-6">
		                                        <div className="form-group">
		                                            <input name="Name" id="name" placeholder="Name *" value={name} className={nameError?"form-control border-danger":"form-control"} type="text" onChange={handleName}/>
		                                            <label className="form-label">Name</label>
		                                        </div>
		                                    </div>
		                                    
		                                    <div className="col-md-6">
		                                        <div className="form-group">
		                                            <input name="Email" id="email" placeholder="Email *" value={email} className={emailError?"form-control border-danger":"form-control"} type="email"  onChange={handleEmail}/>
		                                            <label className="form-label">Email</label>
		                                        </div>
		                                    </div>
		                                    <div className="col-md-6">
		                                        <div className="form-group">
		                                            <input name="Country" id="country" placeholder="Country *" value={country} className={countryError?"form-control border-danger":"form-control"} type="text"  onChange={handleCountry}/>
		                                            <label className="form-label">Country</label>
		                                        </div>
		                                    </div>
		                                    <div className="col-md-6">
		                                        <div className="form-group">
		                                            <input name="City" id="city" placeholder="City *" value={city} className={cityError?"form-control border-danger":"form-control"} type="text"  onChange={handleCity}/>
		                                            <label className="form-label">City</label>
		                                        </div>
		                                    </div>
		                                    <div className="col-md-12">
		                                        <div className="form-group">
		                                            <textarea name="message" id="message" placeholder="Your message *" value={brand} rows="3" className={brandError?"form-control border-danger":"form-control"}  onChange={handleBrand}></textarea>
		                                            <label className="form-label">Name your favorite brands</label>
		                                        </div>
		                                    </div>
		                                    <div className="col-md-12">
		                                        <div className="send">
		                                            <p style={{"fontSize":"80%"}}>Check terms down below</p>
		                                            <button id="submit" className="px-btn theme" onClick={handleForm} type="button" value="Send" disabled={btnDisable}><span id="submit-text">Join now</span> <i className="arrow"></i></button>
		                                            <span id="suce_message" className="text-success" style={{"display": success?"block":"none"}}>Thank you for joining us.</span>
		                                            <span id="err_message" className="text-danger" style={{"display": error?"block":"none"}}>Message Sending Failed</span>
		                                        </div>
		                                    </div>
		                                </div>
		                        </div>
		                    </div>
		                    
		                </div>
		            </div>
		        </section>
		        <section className="section services-section black-bg" id="services">
		            <div className="container">
		                <div className="row justify-content-center">
		                    <div className="col-lg-12">
		                        <div className="section-title">
		                            <h2 style={{"fontSize":"100%"}}>What we do with your name and email</h2>
		                            <p style={{"fontSize":"80%"}}> <mark>Who we are:</mark> tradE2Simple BV is a Dutch company based in Amsterdam, soon starting its business activity in Oslo, Norway. tarde2Simple BV has the registration number 6477354
		                                in the Chamber of Commerce of the Netherlands, VAT number NL855693009B01 and office at Kingsfordweg 151, 1043 GR Amsterdam, Netherlands. You can reach us either by phone +47 973 08 908
		                                or by email: info@trade2simple.com. tradE2Simple BV is the sole controler of your data.
		                            <br /><mark>For what purpose we collect your data:</mark> To let you know in advance, by email, when we launch BOthebot in Norway, Denmark, Sweden and Netherlands.
		                            <br /><mark>Who we collect and process data from:</mark> private consumers. We do not knowingly collect data from children under the age of 13.
		                            <br /><mark>Where we store your data:</mark> Your name and email is stored in a tradE2Simple BV private database located on a Microsoft data center in Norway.
		                            <br /><mark>How we share your data:</mark> Your data is kept within the tradE2Simple BV database and is not shared with any other entity.
		                            <br /><mark>For how long do we keep your data:</mark> We keep your data until you request to delete it from our database. You can request to be removed from our database using the email: info@trade2simple.com or by
		                            using the link delete my data (also included in our thank you email).
		                            <br /><mark>Cookies:</mark> We do not place cookies in your device.
		                            </p>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </section>
		        
		        <footer className="footer white">
		            <div className="container">
		                <div className="row align-items-center">
		                    <div className="col-md-8 m-10px-tb text-center text-md-right">
		                        <p>© 2023 | tradE2Simple BV | copyright all right reserved</p>
		                    </div>
		                </div>
		            </div>
		        </footer>
		    </main>
		</>
	)
}